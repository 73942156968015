<script lang="ts" setup>
import type {RouteLocationNamedRaw} from '#vue-router'
import {onClickOutside} from '@vueuse/core'
import {Menu} from 'primevue'
import type {MenuItem, MenuItemCommandEvent} from 'primevue/menuitem'
import AccountPassword from '~/components/dialogs/AccountPassword.vue'
import Footer from '~/components/Footer.vue'

const { account, hasPermission, hasPermissions } = useAuth()
const dialog = useDialog()
const goTo = (to: RouteLocationNamedRaw) => {
  navigateTo(to)
  toggleSidebar(false)
}
const sidebarEl = ref<HTMLDivElement>()
const showSidebar = ref<boolean>(false)
const toggleSidebar = (value: boolean) =>showSidebar.value = value
onClickOutside(sidebarEl, () =>toggleSidebar(false))

const menu = computed<MenuItem[]>(() => {
  const items = [
    {
      label: 'Case-Share',
      items: [
        {
          label: 'Home',
          command: () => goTo({ name: 'index' })
        }
      ]
    }
  ]

  if (hasPermissions(['filing.view_case', 'filing.view_patient'])) {
    items.push(
      {
        label: 'Filing',
        items: [
          {
            label: 'Cases',
            command: () => goTo({ name: 'filing-cases' })
          },
          {
            label: 'Patients',
            command: () => goTo({ name: 'filing-patients' })
          },
        ]
      }
    )
  }

  if (hasPermission('common.view_user')) {
    const subitems = []

    if (hasPermission('common.add_user')) {
      subitems.push({
        label: 'Users',
        command: () => goTo({ name: 'users' })
      })
    }

    if (subitems.length) {
      items.push(
        {
          label: 'Configuration',
          items: subitems
        }
      )
    }
  }

  return items
})

const accountMenu = ref<typeof Menu>()
const accountMenuItems = ref<MenuItem[]>([
  {
    command: () => navigateTo({name: 'account'}),
    icon: 'pi pi-user',
    label: 'Account'
  },
  {
    command: () => dialog.open(AccountPassword, {
      props: {
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
        header: 'Change password',
        modal: true,
        style: {
          width: '30vw',
        }
      }
    }),
    icon: 'pi pi-key',
    label: 'Change password',
  },
  {
    command: async () => {
      const { logout } = useAuth()
      await logout()
    },
    icon: 'pi pi-sign-out',
    label: 'Sign out',
  }
])

// @ts-ignore
const toggleAccountMenu = (event: Event) => accountMenu.value?.toggle(event)

const siteName = ref<string>("Case-Share")

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value
  },
})

// @ts-ignore
var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
(function () {
  var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = 'https://embed.tawk.to/67a0d8ff3a84273260792829/1ij64va12';
  s1.setAttribute('crossorigin', '*');
  s0?.parentNode?.insertBefore(s1, s0);
})();
</script>

<template>
  <div class="relative min-h-screen">
    <header class="bg-white mb-5 py-3 shadow">
      <div class="flex items-center px-5 space-x-5">
        <Button
          icon="pi pi-bars"
          variant="text"
          rounded
          @click="() => toggleSidebar(true)"
        />

        <NuxtLink
          class="font-mono font-semibold text-2xl text-primary uppercase"
          :to="{name: 'index'}"
        >
          <img src="/images/logo-white-short.png" style="height: 37px;" />
        </NuxtLink>

        <div class="flex flex-grow justify-end">
          <Button
            v-if="account"
            :label="account.name"
            icon="pi pi-user"
            variant="text"
            @click="toggleAccountMenu"
          />
          <Menu ref="accountMenu" :model="accountMenuItems" popup />
        </div>
      </div>

    </header>

    <aside
      class="absolute bg-white min-h-screen left-0 p-5 top-0 transition-transform w-96 z-50"
      :class="{
        'translate-x-0': showSidebar,
        '-translate-x-96': !showSidebar
      }"
      ref="sidebarEl"
    >
      <div class="space-y-5">
        <div class="text-right">
          <Button
            icon="pi pi-times"
            rounded
            severity="secondary"
            variant="text"
            @click="() => toggleSidebar(false)"
          />
        </div>
        <Menu :model="menu" class="border-none" />
      </div>
    </aside>

    <div class="mx-auto px-5">
      <NuxtPage />
    </div>
    <Footer />
  </div>
  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
</template>
