<script lang="ts" setup>
import * as yup from 'yup'
import { yupResolver } from '@primevue/forms/resolvers/yup'
import type {FormSubmitEvent} from '@primevue/forms'
import type {DynamicDialogInstance} from 'primevue/dynamicdialogoptions'

const dialogRef = inject<ComputedRef<DynamicDialogInstance>>('dialogRef')
const loading = ref<boolean>(false)
const toast = useToast()

const onFormSubmit = async ({ states, valid, values }: FormSubmitEvent) => {
  if (!valid) return

  loading.value = true

  const { $api } = useNuxtApp()
  await $api('account/password-change/', {
    method: 'POST',
    body: JSON.stringify(values)
  })
    .then(() => {
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Password changed',
      })

      dialogRef?.value.close()
    })
    .catch(error => processFormErrors(error, toast, states))
    .finally(() => loading.value = false)
}
</script>

<template>
  <DynamicForm
    :fields="[
      {
        as: 'Password',
        feedback: false,
        label: 'Current password',
        name: 'old_password',
        toggleMark: true,
        schema: yup.string().required().label('Current password')
      },
      {
        as: 'Password',
        feedback: true,
        label: 'New password',
        name: 'new_password1',
        toggleMark: true,
        schema: yup.string().required().label('New password')
      },
      {
        as: 'Password',
        feedback: false,
        label: 'Confirm new password',
        name: 'new_password2',
        toggleMark: true,
        schema: yup.string().required().oneOf([yup.ref('new_password1')], 'Passwords do not match').label('Confirm new password')
      }
    ]"
    :loading
    @submit="onFormSubmit"
  />
</template>
